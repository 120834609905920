<template>
  <div class="grid">
    <Toolbar class="col-12" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
      <template #start>
        <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
        <i class="mr-2" />
        <Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
        <i class="mr-2" />
        <Button :label="$t('button.back')" icon="pi pi-backward" @click="onBack()"/>
        <i class="mr-2" />
      </template>
    </Toolbar>
    <div id="receipt_form" class="card p-fluid p-inputtext-sm md:col-6">
      <h5 style="font-weight:bold;">{{ $t('issue.formTitle')}}</h5>
      <div class="field grid">
        <label for="receiptCode" class="mb-2 md:col-3 md:mb-0">{{$t('package.receiptCode')}}</label>
        <form-field class="md:col-7"
          :message="errors.receiptCode">
          <AutoCompleteValue ref="receiptCode" id="receiptCode" type="text"
            :options="receiptCodes"
            v-model="entity.receiptCode" :required="false" autofocus/>
        </form-field>
      </div>
      <div class="field grid">
        <label for="packageCode" class="mb-2 md:col-3 md:mb-0">{{$t('package.packageCode')}}</label>
        <form-field class="md:col-7"
          :message="errors.packageCode">
          <AutoCompleteValue ref="packageCode" id="packageCode" type="text"
            :options="packageCodes"
            v-model="entity.packageCode" :required="false" autofocus/>
        </form-field>
      </div>      
      <div class="field grid">
        <label for="shipmentCode" class="mb-2 md:col-3 md:mb-0">{{$t('issue.shipmentCode')}}</label>
        <form-field class="md:col-7"
          :message="errors.shipmentCode">
          <AutoCompleteValue ref="shipmentCode" id="shipmentCode" type="text"
            :options="shipmentCodes"
            v-model="entity.shipmentCode" :required="false" autofocus/>
        </form-field>
      </div>

      <div class="field grid">
        <label for="description" class="mb-2 md:col-3 md:mb-0">{{$t('common.description')}}</label>
        <form-field :message="errors.description" class="p-float-label md:col-7">
          <Textarea ref="description" id="description" v-model="entity.description" :required="true" type="text" rows="2"/>
        </form-field>
      </div>
    </div>
    <div class="card p-fluid md:col-6">
      <FileUpload ref="issueImages" name="issueImages[]" accept="image/*" :maxFileSize="1000000" :fileLimit="5" :showUploadButton=false>
        <template #empty>
            <p>Drag and drop files to here to upload.</p>
        </template>
      </FileUpload>
    </div>
  </div>
</template>
<script>
//import Form from '@/components/form/Form.vue';
import FormField from '@/components/form/FormField';
import AutoCompleteValue from '@/components/form/AutoCompleteValue';
import IssueService from '@/service/IssueService'
import mixin_form from '@/mixins/form';
export default {
  components: {FormField, AutoCompleteValue},
  mixins:[mixin_form],
  created(){
    /*
    const actions = [
      {
        name: "filterclear",
        icon: "pi pi-filter-slash",
        style: "p-button-outlined mr-2"
      },
      {
        name: "create",
        icon: "pi pi-pencil",
        style: "p-button mr-2"
      }
    ];
    const fields = [
      {
        name: "shipmentCode",
        dataType: 'text'
      },
      {
        name: "receiptCode",
        dataType: 'text'
      },
      {
        name: "packageCode",
        dataType: 'text'
      },
      {
        name: "description",
        dataType: 'text',

      }
    ];
    const metadata = {
      entityName: 'issue',
      fields,
      actions
    };
    this.initMetadata(metadata);
    */
    this.service = IssueService; //this.$api.createService("issue");
  },
  data() {
    return {
      submitted: false,
      packageCodes: [],
      receiptCodes: [],
      shipmentCodes: [],
      entity: {},
      errors: {},
    }
  },
  mounted() {
    this.service.getPackages().then(data => {
      this.packageCodes = data.packageCodes;
      this.receiptCodes = data.receiptCodes;
      this.shipmentCodes = data.shipmentCodes;
    });
    this.service.getReceiptCodes().then(data => {
      this.receiptCodes = data;
    })
  },
  methods: {
    onSave() {
    if(!this.submitted) {
      this.errors = this.validate(this.entity, 'issue');
      if(Object.keys(this.errors).length == 0) {
        if (!this.entity.shipmentCode && !this.entity.packageCode && !this.entity.receiptCode) {
          this.errors['receiptCode'] = this.$t('common.one_required',['packageCode', 'receiptCode', 'shipmentCode']);
        } else {
          this.submitted = true;
          let issueImages = this.$refs['issueImages'];
          this.service.save(this.entity, issueImages.files).then(() => {
              this.$router.push({ name: 'user_issue_list', params: {} })
              this.submitted = false;
            });
          }
        }
      }
    },
    onBack() {
      this.$router.back()
    }
  }
}
</script>
